import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { ApplyButton, SkillCard } from "../components/utils";

const JobDetailPage = () => {
    const { jobId } = useParams();

    const [job, setJob] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://my-json-server.typicode.com/trinc-in/trinc_db/jobs/${jobId}`)
            .then((response) => {
                if (!response.ok) {
                    setJob(null);
                    setLoading(false);
                }
                return response.json();
            })
            .then((data) => {
                setJob(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching job data:", error);
                setLoading(false);
            });
    }, [jobId]);

    if (!loading && !job) {
        console.log("TIME TO GO TO ERROR PAGE");
        return <Navigate to="/404-not-found" />;
    };
    const spacing = "my-8";
    return (
        <>
            <Header showJobsButton={true} buttonText="Back To Jobs" showIcon={true} buttonIcon={"/images/back-arrow.svg"} />
            {loading ? (
                <p className="mx-auto min-h-screen text-5xl text-center mt-32">Loading...</p>
            ) : (
                <div className="min-h-screen lg:w-[1007px] mx-auto py-8 px-3 bg-white mt-16">
                    <div className="flex flex-row justify-between my-6">
                        <h1 className="text-4xl font-bold my-auto">{job.position}</h1>
                        <ApplyButton open={job.active} param={`job_id=${jobId}`} />
                    </div>

                    <p className="text-lg mb-2"><strong>Salary : </strong> {job.salary.currency} {job.salary.amount} {job.salary.frequency}</p>
                    <p className="text-lg mb-2"><strong>Openings : </strong> {job.openings}</p>
                    <p className="text-lg mb-2"><strong>Education : </strong> {job.education}</p>
                    <p className="text-lg mb-2"><strong>Created On : </strong> {job.postedAt}</p>
                    <p className="text-lg mb-2"><strong>Job Type : </strong> {job.contract}</p>
                    <p className="text-lg mb-2"><strong>Location : </strong> {job.location}</p>
                    <div className={spacing}>
                        <strong className="text-lg">Skills : </strong>
                        {job.skills.map((skill) => <SkillCard skill={skill} />)}
                    </div>

                    <p className="text-lg mb-2"><strong>Description : </strong></p>
                    <p className="text-lg mb-2">{job.description.summary}</p>
                    <div className={spacing}>
                        <strong className="text-lg">Responsibilities : </strong>
                        <ul className="list-disc ml-6">
                            {job.description.responsibilities.map((responsibility, index) => (
                                <li key={index} className="text-base">{responsibility}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={spacing}>
                        <strong className="text-lg">Qualifications : </strong>
                        <ul className="list-disc ml-6">
                            {job.description.qualifications.map((qualification, index) => (
                                <li key={index} className="text-base">{qualification}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={spacing}>
                        <strong className="text-lg">Nice To Have : </strong>
                        <ul className="list-disc ml-6">
                            {job.description.niceToHave.map((niceToHave, index) => (
                                <li key={index} className="text-base">{niceToHave}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};

export default JobDetailPage;
