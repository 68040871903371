import Header from "../components/header";
import PaymentCard from "../components/paymentCard";

function RegistrationPage() {
    return (
        <div className="registration">
            <Header showJobsButton={false} />
            <PaymentCard />
        </div>
    );
}

export default RegistrationPage;
