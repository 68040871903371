import React from "react";

const TalkButton = ({ text = "Let's talk", link = "/contact", image = "/images/chat.svg" }) => {
    const style = "bg-[#002EFF] text-white text-center rounded-lg text-[20px] font-system font-bold w-fit hover:bg-[#002EFF] flex flex-row px-4 py-[6px]";
    return (
        <a href={link} className={style}>
            {image ? <img src={image} alt="" width="30px" className="justify-center pr-2 text-white"></img> : null}
            &nbsp;{text}
        </a>
    );
};

export const AchievementCard = ({ number, description, iconPath }) => {
    return (
        <div className="flex flex-col px-6 py-4 mx-3 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl w-44 ">
            <div className="flex justify-between w-full h-10">
                <h2 className="text-red font-bold align-top text-[28px] text-blue-500 justify-center">{number}</h2>
                <img src={iconPath} alt="" className="flex my-auto w-10 justify-center" />
            </div>
            <h1 className="flex w-full font-bold text-[12px] leading-tight">{description}</h1>
        </div>
    );
};

export default TalkButton;

export const DisplayCard = ({ head, text, imgPath, side = "right" }) => {
    const rowOrdering = side === "left" ? `flex flex-col md:flex-row justify-center items-center h-full p-6` : `flex flex-col-reverse md:flex-row justify-center items-center h-full p-6`;
    return (
        <div className="flex flex-col w-fit mt-6 border-2 border-white hover:border-blue-500 rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.1)]">
            <div className={rowOrdering}>
                {side === "left" && (
                    <div className="md:w-1/3 w-72">
                        <img src={imgPath} alt="" className="mx-auto h-full" />
                    </div>
                )}
                <div className="md:w-2/3">
                    <h1 className="text-[32px] font-bold my-[1rem] leading-tight">{head}</h1>
                    <p className="font-serif text-4 leading-7 lg:pr-24">{text}</p>
                </div>
                {side === "right" && (
                    <div className="md:w-1/3 w-72">
                        <img src={imgPath} alt="" className="mx-auto h-full" />
                    </div>
                )}
            </div>
        </div>
    );
};

export const ValueCard = ({ head = null, text = null, imgPath = null, type = "text" }) => {
    return (
        <>
            {type === "text" && (
                <div className="flex justify-center items-center rounded-xl w-full lg:w-[30%] md:w-[45%] sm:w-2/3 h-[280px] p-6 m-3 shadow-[0_3px_12px_rgb(0,0,0,0.1)]">
                    <div className="flex-col">
                        <h1 className="text-[24px] font-bold mb-1 leading-tight text-center">{head}</h1>
                        <p className="font-serif text-4 leading-7 text-center">{text}</p>
                    </div>
                </div>
            )}
            {type === "image" && (
                <div className="flex flex-col rounded-xl w-full lg:w-[30%] md:w-[45%] sm:w-2/3 h-[280px] m-3 shadow-[0_3px_12px_rgb(0,0,0,0.1)]">
                    <img src={imgPath} alt="" className="object-cover w-full h-full" />
                </div>
            )}
        </>
    );
};


export const ApplyButton = ({ open = true, param = null }) => {
    return (
        <>
            {
                open ?
                    <a className="py-2 px-8 text-xl font-bold text-white bg-[#002EFF] rounded-xl my-auto" href={`/apply?${param}`}>Apply</a >
                    : <a className="py-2 px-8 text-xl font-bold text-white bg-[#c5c5c5] rounded-xl my-auto" href="/jobs" onClick={(e) => e.preventDefault()} disabled>Closed</a>
            }
        </>
    );
}

export const SkillCard = ({ skill }) => {
    return (
        <span className=" text-[#002EFF] bg-[#cfe8ff] font-bold h-8 m-1 py-1 px-3 rounded">{skill}</span>
    )
}