import React, { useEffect, useState } from "react";

const PaymentCard = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = setTimeout(() => {
            setIsLoading(false);
        }, 3000);

        return () => clearTimeout(delay);
    }, []);

    return (
        <>
            {
                isLoading ? <p></p> :
                    (
                        < div className="flex flex-col items-center mt-12 mx-auto lg:w-[1500px]" >
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="lg:mr-10 mr-0">
                                    <img src="images/payment-card-99.png" alt="404-Scarecrow" height="auto" width="500px" />
                                </div>
                                <div className="my-auto lg:w-[750px] px-6 lg:px-4">
                                    <h2 class="text-4xl font-bold mb-4">Oops! we are overwelmed</h2>
                                    <p class="mb-4">Thanks for thinking about joining our team! We're excited to have you on board.</p>
                                    <p class="mb-4">
                                        But here's the thing: we're getting lots of applications, and we don't have many people to help us go through all of them.
                                        We want to give your application the attention it deserves, but it's getting hard to keep up!
                                    </p>
                                    <p class="mb-4">
                                        So, we're asking for a little help. If you could chip in 99 INR, it would really make a difference.
                                        It'll help us notice your application and give it a special treatment.
                                    </p>
                                    <p className="font-bold text-lg mb-4">Follow the steps to Stand Out:</p>

                                    <ol class="list-decimal pl-6 mb-4">
                                        <li class="mb-2">
                                            <strong>Payment:</strong> Use your phone to scan the QR code we've got for you.
                                        </li>
                                        <li class="mb-2">
                                            <strong>Screenshot:</strong> After successful payment, take screenshot of the payment.
                                        </li>
                                        <li class="mb-2">
                                            <strong>Email</strong> Send payment screenshot + resume to <strong>hiring@trinc.in</strong>.
                                        </li>
                                        <li class="mb-2">
                                            <strong>Subject:</strong> "Application - [Your Full Name] - [Job Position]".
                                        </li>
                                        <li>
                                            <strong>Confirmation:</strong> We will send you confirmation email.
                                        </li>
                                    </ol>
                                    <p class="mt-4">
                                        Once we get your email, we'll make sure your application gets the attention it deserves.
                                    </p>
                                    <p class="mt-4">
                                        Got any questions? No problem! Just email us at <strong>help@trinc.in</strong>, and we'll be happy to help.
                                    </p>
                                    <p class="mt-4">
                                        Looking forward to having you on board!
                                    </p>
                                </div>
                            </div>
                        </div >
                    )
            } </>
    );
};

export default PaymentCard;
