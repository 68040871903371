import React from "react";
import SectionHeader from "./sectionHeader";
import { DisplayCard } from "./utils";

const Services = () => {
    const developmentText = "TRINC, a software development firm, covers the entire spectrum of services, spanning from conceptualizing product designs to final delivery and launch. We stand prepared to craft your product, developing web and mobile applications that seamlessly meet and fulfill your business goals!";
    const frontText = "Our frontend development service ensures seamless user experiences through responsive and visually appealing interfaces. We specialize in crafting intuitive designs, optimizing performance, and implementing cutting-edge technologies to enhance your web applications.";
    const backText = "Our backend development service focuses on robust and scalable solutions. We specialize in creating efficient server-side architectures, database management, and API integration to power your applications. Our expertise ensures secure, high-performance backend systems tailored to your specific needs.";
    const DBText = "Our database management service ensures optimal performance and reliability. We handle data modeling, indexing, and query optimization to enhance efficiency. Whether it's SQL or NoSQL databases, we implement secure and scalable solutions, providing a solid foundation for your applications.";

    return (
        <section className="services mt-[75px]">
            <div className="px-3">
                <SectionHeader head="Development Services" text={developmentText} />
                <DisplayCard head="Backend Development" text={backText} imgPath="images/backend.png" side="left" />
                <DisplayCard head="Frontend Development" text={frontText} imgPath="images/frontend.png" side="right" />
                <DisplayCard head="Database Management" text={DBText} imgPath="images/database.png" side="left" />
            </div>
        </section>
    );
};

export default Services;
