import React, { useState } from "react";
import { SkillCard } from "./utils";

const JobBoardComponent = ({ job: { id, position, salary, duration, openings, education, postedAt, contract, location, skills, active } }) => {
    const [hovered, setHovered] = useState(false);
    let tags = [];
    if (skills) {
        tags.push(...skills);
    }
    const positionStyle = hovered ? "font-bold text-xl text-[#0044ff]" : "font-bold text-xl";
    return (
        <div
            className={"flex flex-col lg:w-[1007px] lg:flex-row my-6 w-full h-auto p-4 border-2 border-grey hover:border-blue-500 rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.1)]"}
            onClick={() => (window.location.href = `/jobs/${id}`)}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}>

            <div className="flex flex-col justify-between ml-4 lg:w-1/2">
                <h2 className={positionStyle}>{position}</h2>
                <h2 className="text-md">
                    {salary.currency} {salary.amount}&nbsp;<span className="text-[10px]">{salary.frequency}</span>&nbsp; &nbsp; Openings: {openings}
                </h2>
                <p className="text-gray-500">
                    {postedAt} · {contract} · {location}
                </p>
            </div>
            <div className="flex flex-wrap justify-start border-gray-200 mt-4 px-4 border-0 lg:pt-0 lg:mt-0 lg:w-1/2">
                {tags ? tags.map((tag) => <SkillCard skill={tag} />) : ""}
            </div>
        </div>
    );
};

export default JobBoardComponent;
