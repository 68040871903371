import React from "react";
import SectionHeader from "./sectionHeader";
import { ValueCard } from "./utils";

const ClientValues = () => {
    const clientValueText = "Our clients, when asked about the most important advantages of cooperation with TRINC, highlighted the following. Check out the below to see if we have what you’re looking for!";
    const estimationText = "Free project estimation and deep understanding of your needs before the project starts – this is the first saving you get working in cooperation with TRINC.";
    const budgetText = "Work done in accordance with specifications, within budget, and on time. Always predictable and stable cooperation.";
    const workShopText = "We are not waiting for a ready-made action plan, we use a proactive approach. We are here to help you in the creation of product strategy and in making the key decisions.";
    const dedicatedText = "Each project has a dedicated team with a certified project manager and other experts with excellent technical skills in their field depending on the project requirements.";
    return (
        <section className="services mt-[75px]">
            <div className="px-3">
                <SectionHeader head="Why do our clients value us?" text={clientValueText} />
                <div className="flex flex-wrap justify-around">
                    <ValueCard head="Free Estimation" text={estimationText} />
                    <ValueCard imgPath="images/team01.png" type="image" />
                    <ValueCard head="On budget, on-time" text={budgetText} />
                    <ValueCard imgPath="images/team02.png" type="image" />
                    <ValueCard head="Project workshops" text={workShopText} />
                    <ValueCard head="Dedicated team" text={dedicatedText} />
                    <ValueCard head="Passionate people" text={estimationText} />
                    <ValueCard imgPath="images/team03.png" type="image" />
                    <ValueCard head="Code ownership" text={budgetText} />
                    <ValueCard imgPath="images/team04.png" type="image" />
                    <ValueCard head="High-quality Work" text={estimationText} />
                    <ValueCard imgPath="images/team05.png" type="image" />
                </div>
            </div>
        </section>
    );
};

export default ClientValues;
