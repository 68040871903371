import Header from "../components/header";
import Footer from "../components/footer";
import JobBoardComponent from "../components/jobPost";

import React, { useEffect, useState } from "react";

function JobPosts() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const vacancy = true;

    useEffect(() => {
        if (vacancy) {
            fetch("https://my-json-server.typicode.com/trinc-in/trinc_db/jobs")
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setJobs(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching job data:", error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [vacancy]);

    const noVacancyElement = (
        <div className="flex justify-center mt-10">
            <img src="images/no-vacancy.png" width="800px" height="auto" alt="" />
        </div>
    );

    return (
        <>
            <Header showJobsButton={false} />
            <div className="min-h-screen mt-20 lg:w-[1007px] mx-auto">
                {loading ? (
                    <p className="mx-auto text-5xl text-center mt-32">Loading...</p>
                ) : jobs.length > 0 && vacancy ? (
                    <>
                        <h1 className="text-[32px] font-bold w-fit ml-4">Job Openings</h1>

                        {jobs.map((job) => (
                            <JobBoardComponent job={job} onClick={() => (window.location.href = `/jobs/{id}`)} />
                        ))}
                    </>
                ) : (
                    noVacancyElement
                )}
            </div>
            <Footer />
        </>
    );
}

export default JobPosts;
