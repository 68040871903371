import React from "react";
import TalkButton from "./utils";

const Header = ({ showJobsButton = true, buttonText = "Apply", showIcon = true, buttonIcon = "/images/job.svg" }) => {
    return (
        <header className="fixed top-0 left-0 w-full bg-white py-2 h-16 shadow-[0_4px_7px_rgba(0,0,0,0.25)]">
            <div className="flex flex-row justify-between mx-auto lg:w-[1007px]">
                {/* LOGO */}
                <div className="h-12" onClick={() => (window.location.href = "/")}>
                    <img src="/images/header-logo.png" alt="Logo" className="h-12" />
                </div>

                {/* NAVIGATIONS */}
                <div className="flex h-12 justify-center items-center pr-2">{showJobsButton ? <TalkButton text={buttonText} link="/jobs" image={buttonIcon} /> : null}</div>
            </div>
        </header>
    );
};

export default Header;
